export default {
	"fca1": "FCAアカウント",
	"fca1-m": "FCAアカウント",
	"fca2": "EBC Financial Group (UK) Ltdは、英国金融行動監視機構（FCA）によって認可および監督されている総合な金融証券会社であり、「Matched Principal Broker」として仲介業務を行い、すべての注文は直接リクイディティプロバイダーとマッチングされます。<br/>登録番号：927552",
	"fca3": "EBC Financial Group (UK) LtdでFCA口座を開設した場合、FSCS補償プランに基づいて最大85,000ポンドを請求する権利があります。<br/><br/>FCAの規定により、EBC Financial Group (UK) LtdにてFCA口座を開設できるのはプロ投資家（特定投資家または適格機関投資家を含む）のみとなります。レバレッジは100倍でお客様の資金はバークレイズ銀行の弊社法人口座で分別管理されます。",
    "fca4":{
		"title":"プロ投資家は次の３つの基準のうち2つを満たす必要があります：",
		"title-m":"プロ投資家は次の３つの基準のうち2つを満たす必要があります：",
		"desc":"過去1年間において、四半期ごとの平均取引回数が10回以上あること。",
		"desc1":"現預金やその他の金融商品の合計額が50万ユーロ以上であること。",
		"desc2":"金融業界で1年以上の勤務経験があり、CFD（差金決済取引）や金融派生商品取引についての知識を有する者。"
	},
	"fca5":{
		"title":"FCA口座開設方法",
		"desc":"申込書をダウンロードし記入",
		"desc1":"書類の準備と認証",
		"desc2":"Emailで申請書類を提出",
		"desc3":"コンプライアンス審査と書類への署名",
		"desc4":"口座開設完了",
		"btn":"特定投資家向け申込書",
		"btn1":"適格機関投資家向け申込書"
	},
	"fca6":{
		"title":"厳格な金融規制と高い資金安全性",
		"title-m":"厳格な金融規制と高い資金安全性",
		"desc":"英国金融行動監視機構 (FCA)",
		"desc1":"FCA口座お問い合わせ：",
		"desc2":"免責事項：本ページならびに弊社のFCAに関するコンテンツはグローバルサイトからの翻訳に留まり、特定の国や地域の居住者に対して金融サービスの提供や宣伝、勧誘を目的としていません。"
	},
	"lang":"en"
}

