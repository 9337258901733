export default {
    "box-name": "",
    "public-btn-text1": "今すぐ取引",
    "public-btn-text2": "すべての製品",
    screen0: {
        "title": "CFD配当金",
        "desc": `CFD配当金は、MT4アカウントの残高に"Dividend"と注記され、入金または出金として付与されます。`,
    },
    screen1: {
        "title": "最大レバレッジと証拠金率",
    },
    screen2: {
        "title": "金融市場に簡単アクセス",
    }
}