export default {
	"pages1":{
		"title":"コピートレード",
		"tips":"トレードの達人と、ここで出会う",
		"text":"選ばれる理由",
		"text1":"シグナル提供者",
		"text2":"シグナル提供者向け報酬プログラム",
		"text3":"大賞",
		"text4":"コミュニティにログイン"
	},
	"pages2":{
		"title":"選ばれる理由",
		"list":[{
			"tit":"柔軟なコピートレード設定",
			"tips":"EAプラグインなしで、高度なフォロー設定が可能"
		},{
			"tit":"ミリ秒単位の迅速なコピー速度",
			"tips":"自分で手を動かす必要がなく、迅速に取引シグナルをコピー可能"
		},{
			"tit":"6次元モデル分析で一目瞭然",
			"tips":"シグナルの成績を6次元で評価し、優秀なシグナルをすぐに見つけられる"
		},{
			"tit":"AIスクリーニングツール",
			"tips":"世界中の優秀なシグナルの中から、取引戦略に適したシグナルを簡単に検索"
		},{
			"tit":"透明性の高い取引情報開示",
			"tips":"すべての取引履歴と実績をレビュー可能"
		}],
		"btn":"コミュニティにログイン"
	},
	"pages3":{
		"title":"シグナル紹介",
		"text":"収益率",
		"text1":"最大ドローダウン",
		"text2":"シャープレシオ",
		"text3":"損益率",
		"text4":"無料",
		"text5":"フォロー",
		"btn":"シグナル一覧"
	},
	"pages4":{
		"title":"シグナル提供者向け報酬プログラム",
		"num":"100ドル/月",
		"num1":"300ドル/月",
		"num2":"1,000ドル/月",
		"num3":"5,000ドル/月",
		"num4":"10,000ドル/月",
		"text1":"運用資産残高",
		"text2":"フォロワー数",
		"name":"初級トレーダー",
		"name1":"中級トレーダー",
		"name2":"上級トレーダー",
		"name3":"スタートレーダー",
		"name4":"レジェンドトレーダー",
		"tips":"'+1% 管理資金/年 (毎月支払い)",
		"btn":"今すぐ参加"
	},
	"pages5":{
		"title":"サービスプラン",
		"text":"24時間日本語サポート",
		"text1":"EBCマーケティングキャンペーンへの参加",
		"text2":"VPSスポンサーシップ",
		"text3":"投資参考書籍のプレゼント",
		"text4":"オーダーフロー分析ツールへのアクセス",
		"text5":"EBCオリジナルノベルティ",
		"text6":"実績上位への表示",
		"text7":"当社海外オフィス訪問",
		"text8":"メディア宣伝サポート",
		"text9":"ブランド提携",
		"text10":"ファンド立上げにおけるアドバイザリーサービス",
		"btn":"コミュニティにログイン"
	}
}